<template>
  <VDialog
    class="br-12"
    max-width="760"
    :value="dialog"
    @click:outside="close"
  >
    <VCard class="pa-6">
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <p class="form-headline">
            {{ isEditing ? 'Редактировать тариф' : 'Выбрать тариф' }}
          </p>
          <slot name="inner-btn" />
        </div>
        <RoundButton
          color="#E5E5E5"
          elevation="0"
          icon="mdi-close"
          icon-color="#565656"
          :max-height="30"
          :max-width="30"
          src=""
          @click="close"
        />
      </div>
      <VContainer fuild>
        <VForm ref="form">
          <VTextField
            v-model="search"
            class="search-width"
            color="success"
            dense
            outlined
            placeholder="Введите название тарифа"
            prepend-inner-icon="mdi-magnify"
            @input="searchTariff"
          />
          <VList
            ref="list"
            class="overflow-y-auto br-grey py-0"
            max-height="292"
          >
            <VListItemGroup
              v-if="filteredTariffs && filteredTariffs.length"
              v-model="selected"
              color="success"
            >
              <div
                v-for="(tariff, index) in filteredTariffs"
                :key="index"
              >
                <VListItem :id="`el${tariff.id}`" :value="tariff.id">
                  <VListItemTitle class="font-weight-bold" v-text="tariff.title" />
                </VListItem>
                <VDivider v-if="index < (filteredTariffs.length - 1)" class="mx-0 px-0" />
              </div>
            </VListItemGroup>
            <VListItem v-else class="text-center py-10">
              <VListItemTitle> {{ noDataText }} </VListItemTitle>
            </VListItem>
          </VList>
        </VForm>
        <VRow class="mb-3 mt-10" justify="center">
          <VBtn
            class="default-btn cancel-btn elevation-0 mr-3"
            color="#E0E0E0"
            x-large
            @click="close"
          >
            Отменить
          </VBtn>
          <VBtn
            class="default-btn save-changes-btn elevation-0"
            color="success"
            :disabled="!selected"
            width="343"
            x-large
            @click="save"
          >
            <span class="mx-4">Включить тариф в хабе</span>
          </VBtn>
        </VRow>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script>
import RoundButton from '@/components/RoundButton.vue';

export default {
  components: {
    RoundButton,
  },
  props: {
    item: {
      type: Object,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    tariffs: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'update', 'open'],
  data() {
    return {
      filteredTariffs: [],
      selected: null,
      search: '',
      noDataText: '',
      tariffId: null,
      key: 0,
    };
  },
  methods: {
    scrollTo(elementId) {
      this.$vuetify.goTo(`#el${elementId}`, { container: this.$refs.list });
    },
    searchTariff(search) {
      const tariffs = this.tariffs.filter((item) => item.isActive === true);
      if (search) {
        this.filteredTariffs = tariffs.filter(
          (tariff) => tariff.title.toLowerCase().startsWith(search),
        );
        const searchTariff = this.filteredTariffs.reduce(
          (a, b) => (a.title.length <= b.title.length ? a : b),
        );
        this.selected = searchTariff.id || null;
        this.scrollTo(searchTariff.id);
      } else {
        this.selected = null;
        this.filteredTariffs = tariffs;
      }
    },
    save() {
      const { ...currentItem } = this.item;
      currentItem.hotTariff = { id: this.selected };
      this.$emit('update', currentItem);
      this.close();
    },
    incrementKey() {
      this.key += 1;
    },
    close() {
      this.$emit('close');
      this.selected = null;
      this.search = '';
    },
    setInitialValue(hotTariff) {
      this.noDataText = 'Загрузка данных...';
      const tariffId = hotTariff ? hotTariff.id : null;
      this.filteredTariffs = this.tariffs.filter((item) => item.isActive === true);
      if (tariffId) {
        this.selected = tariffId;
        this.scrollTo(this.selected);
      }
      if (this.filteredTariffs.length === 0) {
        this.noDataText = 'Нет данных';
      }
    },
  },
  watch: {
    dialog: {
      immediate: false,
      handler(val) {
        if (val) {
          this.incrementKey();
          this.$emit('open', this.key);
        }
      },
    },
    item: {
      handler(val) {
        this.setInitialValue(val?.hotTariff);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/menu";

.save-changes-btn {
  width: 204px;
}
.default-btn {
  border-radius: 12px;
}
.search-width {
  width: 410px;
}
.br-grey {
  border: 1px solid #E7E7E7;
  border-radius: 0;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #F4F4F4;
  border-left: 0.5px solid #E7E7E7;
}
::-webkit-scrollbar-thumb {
  background-color: #C7CACE;
  border: 3px solid transparent;
  border-radius: 30px;
  background-clip: content-box;
}
</style>
